import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "intersection-observer"; // Polyfill for Intersection Observer
import "./AboutText.css";

function AboutText() {
  const aboutTextRef = useRef(null);
  const headerRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Animation for the header
          gsap.fromTo(
            headerRef.current,
            { y: 100, opacity: 0 }, // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out" } // End state
          );

          // Animation for the description
          gsap.fromTo(
            descriptionRef.current,
            { y: 100, opacity: 0 }, // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out", delay: 0.5 } // End state
          );

          observer.unobserve(entry.target); // Stop observing after animation plays
        }
      });
    });

    const currentAboutTextRef = aboutTextRef.current;
    if (currentAboutTextRef) {
      observer.observe(currentAboutTextRef);
    }

    return () => {
      if (currentAboutTextRef) {
        observer.unobserve(currentAboutTextRef);
      }
    };
  }, []);

  return (
    <div id="abouttext" ref={aboutTextRef}>
      <h1 ref={headerRef} className="abouttext-header">
        Kdo jsem
      </h1>
      <h3 ref={descriptionRef} className="abouttext-description">
        Jmenuji se Jan Schejbal. <br /> Programování se věnuji jíž téměř 6 let.
        Profesně poslední zhruba dva roky. Pracuji momentálně jako vývojář na
        volné noze. V mých pracovních projektech nejčastěji využívám Nette
        framework v PHP a Doctrine ORM, Flutter. <br />
        <br />
        Rád bych rozšířil svoje obzory o moderní tech stack, a proto si zkouším
        neustále nové technologie. Ať už se jedná o React, Python (Django), Next.js, TypeScript, TailwindCSS, Redis, MongoDB, PostgreSQL,
        atd. Jsou k nalezení na mé Github stránce.
      </h3>
    </div>
  );
}

export default AboutText;
