import React, { useEffect } from "react";
import Showcase from "../components/Home/Showcase";
import Card from "../components/Home/Card";
import computerImage from "../assets/images/Motiv.png";
/* import MobileApp from "../assets/images/huraliga.png";
 */
import DecorText from "../components/Home/DecorText";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Home.css";

function Home() {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    document.body.style.color = "black";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = ""; // Reset při odchodu z komponenty
    };
  }, []);

  return (
    <>
    <Header />
      <Showcase />
      <Card
        backgroundColor="#EFF5FD"
        textColor="#00397C"
        text="Ahoj, já jsem Honza, nadšený vývojář. 👋"
        description="O mně"
        image={computerImage}
        link="/o-mne"
      />
      {/* <Card
        backgroundColor="#FDF0F2"
        textColor="#770012"
        text="Mobilní aplikace"
        description="Huráliga"
        image={MobileApp}
        imgId="mobileapp"
        link="/prace/huraliga"
      /> */}
      <DecorText
        textAlign="left"
        text="Frontend"
        paddingLeft="100px"
        paddingRight="0"
        description="HTML, CSS, SCSS, TailwindCSS, Flutter, React, JavaScript, TypeScript"
      />
      <DecorText
        textAlign="right"
        text="Backend"
        paddingLeft="0"
        paddingRight="100px"
        description="PHP (Nette), Python (Django), Next.js, MySQL, PostgreSQL, MongoDB, Redis"
      />
      <DecorText
        textAlign="left"
        text="Nástroje"
        paddingLeft="100px"
        paddingRight="0"
        description="PHPStorm, WebStorm, Visual Studio Code, Git, Docker, Postman"
      />
      <Footer backgroundColor="white" textColor="black" />
    </>
  );
}

export default Home;
